.app-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 23.6% 76.4%;
  grid-template-rows: 100%;
}

.app-container > * {
  border-left: 1px solid black;
}

.app-container > *:first-child {
  border-left: none;
}

.sidebar {
  margin: 1em;
  display: flex;
  flex-direction: column; /* flex-direction: row; after breakpoint  */
  gap: 3em;
  justify-content: space-between;
}

.logo {
  display: flex;
}

.menu {
  overflow: scroll;
  display: flex;
  flex-direction: column; /* flex-direction: row; after breakpoint  */
  gap: 1em;
}

.mobile.menu {
  display: none;
}

.menu-hamburger-mobile {
  display: none;
}

a {
  color: black;
  text-decoration: none;
}

.menu-link {
  font-size: 2em;
}

.menu-link.active {
  text-decoration: line-through dashed;
}

.menu-link.smaller {
  font-size: 1.2em;
}

.menu-link:hover {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
}

.page-container {
  width: 100%;
  height: 100%;
}

.page-container > * {
  border-top: 1px solid black;
}

.page-container > *:first-child {
  border-top: none;
}

.page-media {
  height: 61.8%;
  margin-bottom: 0;
}

/* .page-media:hover {
  cursor: pointer;
} */

.page-carousel {
  width: 100%;
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.page-carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-carousel-vid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-carousel-vid-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.page-carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.page-carousel-img:hover, .page-carousel-vid:hover {
  cursor: pointer;
}

.page-content {
  height: 38.2%;
  display: flex;
}

.page-content > * {
  border-left: 1px solid black;
}

.page-content > *:first-child {
  border-left: none;
}

.page-submenu {
  width: 17%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  overflow-y: scroll;
}

.page-content-text {
  flex: 1;
  padding: 1em;
  overflow-y: scroll;
}

.contact-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.contact-form {
  width: 61.8%;
  max-width: 800px;
}

.mobile-menu-overlay {
  display: none;
}


@media (max-width: 700px) {
  .app-container {
    grid-template-rows: 14.6% 85.4%;
    grid-template-columns: 100%;
  }

  .app-container > * {
    border-left: none;
    border-top: 1px solid black;
  }
  
  .app-container > *:first-child {
    border-top: none;
  }

  .sidebar {
    flex-direction: row;
  }

  .menu {
    display: none;
  }

  .menu-hamburger-mobile {
    display: inline;
    padding: 1em;
    background: transparent;
    border: none;
  }

  .mobile.menu {
    display: flex;
    max-width: calc(100vw - 3em);
    width: 400px;
    height: calc(100vh - 2px);
    background-color: white;
    position: absolute;
    top: 0;
    left: calc(-400px - 2px);
    z-index: 99999999999;
    transition: left 0.5s;
    border: 1px solid black;
    overflow: scroll;
    padding: 1em;
  }

  .mobile.menu.show {
    left: 0;
  }

  .mobile-menu-overlay {
    display: inline;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999;
    animation: 'fadeIn 5s';
    background-color: black;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    cursor: pointer;
  }
  .mobile-menu-overlay.show {
    visibility: visible;
    opacity: 0.5;
  }

  .page-media {
    height: 53%;
  }

  .page-content {
    height: 47%;
  }

  .page-submenu {
    display: none;
  }

  .page-submenu:last-of-type {
    display: flex;
    min-width: min-content;
  }

  .contact-form {
    width: 100%;
  }
}
