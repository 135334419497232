.archive-container {
  padding: 1em;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.archive-group-item {
  width: 250px;
  height: 200px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  
}

.archive-group-item:hover {
  cursor: pointer;
}

.archive-group-item-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.archive-group-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  gap: 0.5em;
} 

.archive-group-img {
  flex: 1;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}